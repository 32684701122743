<template>
  <section>
    <h2><em>Breh...</em></h2>
    <h3>
      Go <router-link to="/search"><em>back</em></router-link>
    </h3>

    <img
      src="../assets/cat-loader.jpg"
      alt="Breh..."
      height="400"
      width="400"
    />
  </section>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>