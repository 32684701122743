<template>
  <section>
    <h2>Thank you for submitting your nominations!</h2>
    <p>You can return back home <router-link to="/search">here</router-link></p>
  </section>
</template>

<script>
export default {
  name: 'ThankYou',
};
</script>